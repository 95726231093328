<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['onlyGreasyInSummer'])">
      <single-answer-question
        title="label.onlyGreasyInSummer"
        :value="onlyGreasyInSummer"
        :options="$options.yesNoResponseOptions"
        yes-no
        dysfunction="skin-oiliness"
        @input="onFieldChange('onlyGreasyInSummer', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'EnvironmentTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    onlyGreasyInSummer: {
      type: String,
      default: ''
    }
  }
};
</script>
